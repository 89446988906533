import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import { Link } from "gatsby";

const Demo = () => {
  const [strategyInPlace, setStrategyInPlace] = useState("Yes");
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFail, setSendFail] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
  const demoFormRef = useRef();

  useEffect(() => {
    let navBar = document.getElementById("mainNav");
    navBar.classList.add("navbar-shrink");

    document
      .getElementById("but_demoPg_demoSubmit")
      .addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (demoFormRef.current.checkValidity()) {
          submitRequestDemoPg();
        }
        demoFormRef.current.classList.add("was-validated");
      });
  }, []);

  async function submitRequestDemoPg() {
		let demoSuccess = false;
    const userFname = document.getElementById("txt_demoPg_firstname").value;
    const userLname = document.getElementById("txt_demoPg_lastname").value;
		const userCompany = document.getElementById("txt_demoPg_company").value;
    const userEmail = document.getElementById("txt_demoPg_email").value;
    const userRole = document.getElementById("txt_demoPg_role").value;
    const scheduleOptionsChx = document.getElementsByName(
      "demoStrategyInPlace"
    );
    let schedulePreference = "";
    for (let option of scheduleOptionsChx) {
      if (option.checked) {
        schedulePreference = option.value;
        break;
      }
    }

    const zohoForm = {
      postUrl:
        "https://forms.zohopublic.com/dmoore3/form/EyedogDemoLandingPage/formperma/_TsiEZE7d5S6lpr3aRKsv4NkBf5qVdsHbUmBzlkTU7o/htmlRecords/submit",
      formFields: {
        zf_referrer_name: "",
        zf_redirect_url: "",
        zc_gad: "",
        Name_First: userFname,
        Name_Last: userLname,
        SingleLine: userRole,
				SingleLine1: userCompany,
        Email: userEmail,
        Radio: schedulePreference,
      },
    };

    // this sends the email via api
    let apiObj = new Api();
    let sendResults = await apiObj.sendZohoForm(
      zohoForm,
      "spinner-sendDemoPgMail"
    );

    switch (sendResults?.message.toUpperCase()) {
      case "SUCCESS":
				demoSuccess = true;
        setSendSuccess(true);
        break;
      default:
				//sendResults.message
      	setSendFail(true);
				setErrorMsg(sendResults?.message)
    }

		if (demoSuccess) {
			demoFormRef.current.classList.remove("was-validated");
			demoFormRef.current.reset();
		}

  }

  return (
    <Layout>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                main > .container {
                    padding: 150px 15px 0;
                }
            `,
        }}
      />
      <Helmet
        title="Request your free demo"
        bodyAttributes={{ class: "d-flex flex-column h-100" }}
      />
      <main class="flex-grow-1 bg-light">
        <div class="container">
          <section>
            <h1 className="mt-0">
              Are you ready to enhance your indoor wayfinding?
            </h1>

            <div className="row">
              <div
                className="col-md-6 mb-2"
                data-aos="slide-up"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <h3 className="text-primary">You are in the right place.</h3>
                <p>
                  Eyedog.us brings a whole new dimension to the world of
                  wayfinding. Enhance your patient experience. Seeing is
                  believing, request your free demo below:
                </p>
                <form
                  id="form_demoPg"
                  className="needs-validation"
                  novalidate
                  ref={demoFormRef}
                >
                  <div className="row">
                    <div className="col-md-6 mb-2 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demoPg_firstname"
                        title="First Name"
                        placeholder="Enter your first name..."
                        aria-describedby="FirstName"
                        required
                      />
                      <label for="txt_demoPg_firstname">First name</label>
                      <div className="invalid-feedback">
                        Please provide your first name.
                      </div>
                    </div>
                    <div className="col-md-6 mb-2 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demoPg_lastname"
                        title="Last Name"
                        placeholder="Enter your last name..."
                        aria-describedby="LastName"
                        required
                      />
                      <label for="txt_demoPg_lastname">Last name</label>
                      <div className="invalid-feedback">
                        Please provide your last name.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-2 form-floating">
										<input
                        type="text"
                        className="form-control"
                        id="txt_demoPg_company"
                        title="Company"
                        placeholder="Enter your company..."
                        aria-describedby="Company"
                        required
                      />
                      <label for="txt_demoPg_company">Company</label>
                      <div className="invalid-feedback">
                        Please provide your company name.
                      </div>
                    </div>
									</div>
                  <div className="row">
                    <div className="col-md-6 mb-2 form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demoPg_role"
                        title="Role"
                        placeholder="Enter your role..."
                        aria-describedby="Role"
                      />
                      <label for="txt_demoPg_role">Role</label>
                    </div>
                    <div className="col-md-6 mb-2 form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="txt_demoPg_email"
                        title="Email"
                        placeholder="Enter your email..."
                        aria-describedby="Email"
                        required
                      />
                      <label for="txt_demoPg_email">Email</label>
                      <div className="invalid-feedback">
                        Please provide your email.
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col form-floating text-center">
                      <p>Does your health system have a wayfinding strategy in place?</p>
                      <div
                        className="form-check form-check-inline"
                        onClick={() => {
                          setStrategyInPlace("Yes");
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="demoStrategyInPlace"
                          id="rad_demoPg_strategy_1"
                          value="Yes"
                          checked={strategyInPlace === "Yes"}
                        />
                        <label
                          className="form-check-label"
                          for="rad_demoPg_strategy_1"
                        >
                          Yes
                        </label>
                      </div>
                      <div
                        className="form-check form-check-inline"
                        onClick={() => {
                          setStrategyInPlace("No");
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="demoStrategyInPlace"
                          id="rad_demoPg_strategy_2"
                          value="No"
                          checked={strategyInPlace === "No"}
                        />
                        <label
                          className="form-check-label"
                          for="rad_demoPg_strategy_2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      {sendSuccess && (
                        <div
                          className="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          <i className="bi bi-check-circle-fill me-2"></i>
                          <strong>
                            Thank you for your interest in Eyedog.US.
                          </strong>
                          <br/>We look forward to meeting you and sharing the power
                          of Eyedog wayfinding technology. A representative will
                          contact you shortly to schedule a demo.
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      )}
											{sendFail && (
												<div class="alert alert-danger" role="alert">
													<strong>Error:</strong> {errorMsg}
												</div>
												)
											}
                      <button
                        type="submit"
                        className="btn btn-primary form-control"
                        id="but_demoPg_demoSubmit"
                      >
                        <i
                          className="spinner-border spinner-border-sm buttonspinner"
                          role="status"
                          id="spinner-sendDemoPgMail"
                        ></i> <h4 className="mt-1">Request Your Demo</h4>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 mb-4">
                <div
                  className="embed-responsive embed-responsive-16by9"
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/ZrmS2Q0aGvM"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default Demo;
